import {
  CaseResponse,
  EMediaSequenceState,
  EMediaSequenceType,
  EPermission,
  FolderResponse,
  MediaSequenceCategoryResponse,
  MediaSequenceResponse,
  MediaSequenceUpdateRequest,
  UserSimpleResponse,
} from 'api/core';
import { useSearchFolders } from 'api/useFoldersApi';
import { useGetMediaSequenceCategories } from 'api/useMediaSequenceCategoriesApi';
import { useUpdateMediaSequence } from 'api/useMediaSequencesApi';
import { useSearchUsers } from 'api/useUsersApi';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { LabelWithHelperText } from 'components/Form/LabelWithHelperText';
import { EntitySelectSingle } from 'components/Select/EntitySelectSingle';
import { EntitySelectSingleAsync } from 'components/Select/EntitySelectSingleAsync';
import { CaseUpdate } from 'pages/Project/components/Case/CaseUpdate';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  mediaSequenceStateTranslate,
  mediaSequenceTypeTranslate,
} from 'utils/enum-translate';

interface MediaSequenceUpdateFormProps {
  mediaSequence: MediaSequenceResponse;
  onSuccess?: () => void;
  onCancel?: () => void;
  disabled?: boolean;
}

export const MediaSequenceUpdateForm = ({
  mediaSequence,
  onSuccess,
  onCancel,
  disabled,
}: MediaSequenceUpdateFormProps) => {
  const [caseEditTarget, setCaseEditTarget] = useState<CaseResponse | null>(
    null
  );
  const { mutateAsync, isPending } = useUpdateMediaSequence();
  const { data: categories } = useGetMediaSequenceCategories();

  const { register, handleSubmit, setValue } =
    useForm<MediaSequenceUpdateRequest>({
      defaultValues: {
        ...mediaSequence,
        metaMappingUserId: mediaSequence.metaMappingUser?.id ?? null,
      },
      disabled,
    });

  const onSubmit = handleSubmit(async (result) => {
    await mutateAsync({
      id: mediaSequence.id,
      mediaSequenceUpdateRequest: result,
    });
    onSuccess?.();
  });

  const onUserSelected = (userResponse: UserSimpleResponse | null) => {
    setValue('metaMappingUserId', userResponse?.id);
  };

  const onFolderSelected = (folderResponse: FolderResponse | null) => {
    setValue('folderId', folderResponse?.id, { shouldValidate: true });
  };

  const onCategorySelected = (
    category: MediaSequenceCategoryResponse | null
  ) => {
    setValue('mediaSequenceCategoryId', category?.id);
  };

  if (!categories) {
    return null;
  }

  const isDraft = mediaSequence.state === EMediaSequenceState.Draft;

  return (
    <>
      <form className="flex flex-wrap" onSubmit={onSubmit}>
        <div className="space-y-2 w-full">
          <PermissionProtectedComponent
            permissions={[EPermission.UserSimpleSearch]}
          >
            <div className="form-control">
              <LabelWithHelperText
                label="Tilknyt mægler"
                helperText="Der bliver automatisk indhentet stamdata for den valgte mægler."
              />
              <EntitySelectSingleAsync<
                UserSimpleResponse,
                { searchTerm?: string }
              >
                useSearchFunction={useSearchUsers}
                searchFunctionOrder={(a, b) => a.name.localeCompare(b.name)}
                searchParams={{}}
                renderSuggestion={(userSuggestion) => (
                  <>
                    {userSuggestion.name}{' '}
                    {userSuggestion.title
                      ? `(${userSuggestion.title})`
                      : undefined}
                  </>
                )}
                onSuggestionSelected={onUserSelected}
                initialValue={mediaSequence.metaMappingUser}
                disabled={disabled || !isDraft}
              />
              {/* TODO: Add endpoint to check what fields will be lost if they acutally change */}
              <div className="label">
                <p className="text-sm">
                  Vær opmærksom på at hvis du ændre en tilknyttet mægler, så vil
                  de foruddefinerede værdier på dine dynamiske overlejringer
                  tage udgangspunkt i den nye mægler.
                </p>
              </div>
            </div>
          </PermissionProtectedComponent>

          <PermissionProtectedComponent permissions={[EPermission.FolderRead]}>
            <div className="form-control">
              <LabelWithHelperText
                label={<p>Tilknyt mappe</p>}
                helperText="Vælg hvilken mappe videoen skal tilknyttes."
              />
              <EntitySelectSingleAsync<FolderResponse, { searchTerm?: string }>
                useSearchFunction={useSearchFolders}
                searchFunctionOrder={(a, b) => a.name.localeCompare(b.name)}
                searchParams={{}}
                renderSuggestion={(folderSuggestion) => (
                  <>{folderSuggestion.name}</>
                )}
                initialValue={(u) => u.id === mediaSequence?.folderId}
                onSuggestionSelected={onFolderSelected}
              />

              <input
                {...register('metaMappingUserId')}
                type="text"
                className="input input-bordered hidden"
              />
            </div>
          </PermissionProtectedComponent>

          <div className="form-control">
            <LabelWithHelperText
              label="Kategori"
              helperText="Kategorien som Smart Videoen tilhører."
            />
            <EntitySelectSingle<MediaSequenceCategoryResponse>
              data={categories.data}
              renderFormat={(category) => category.name}
              onSelect={onCategorySelected}
              searchPropertyKey="name"
              initialValue={categories.data.find(
                (category) =>
                  category.id === mediaSequence.mediaSequenceCategoryId
              )}
              disabled={
                disabled || mediaSequence.type === EMediaSequenceType.Strict
              }
            />
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Adresse</span>
            </label>
            <input
              disabled
              className="input input-bordered w-full"
              placeholder={mediaSequence.name}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Status</span>
            </label>
            <input
              className="input input-bordered join-item w-full"
              disabled
              placeholder={mediaSequenceStateTranslate(mediaSequence.state)}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Oprettet af</span>
            </label>
            <input
              className="input input-bordered join-item w-full"
              disabled
              placeholder={mediaSequence.user?.name}
            />
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Type</span>
            </label>
            <input
              className="input input-bordered join-item w-full"
              disabled
              placeholder={mediaSequenceTypeTranslate(mediaSequence.type)}
            />
          </div>
        </div>

        {disabled ? null : (
          <div className="flex justify-center space-x-4 pt-4 w-full">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isPending}
            >
              Opdater
            </button>
            {onCancel ? (
              <button type="button" className="btn" onClick={onCancel}>
                Annuller
              </button>
            ) : null}
          </div>
        )}
      </form>
      {caseEditTarget ? (
        <CaseUpdate
          id={caseEditTarget.id}
          projectId={caseEditTarget.project.id}
          isInitialOpen={true}
          onClosed={() => setCaseEditTarget(null)}
        />
      ) : null}
    </>
  );
};
