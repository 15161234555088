import { ColumnDef } from '@tanstack/react-table';
import { MediaSequenceBranchResponse, MediaSequenceResponse } from 'api/core';
import { CustomTable } from 'components/Table/CustomTable';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';
import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { LinkCell } from 'components/Table/Cell/LinkCell';
import { useIsMobile } from 'utils/useIsMobile';
import { AssetPreviewModal } from 'components/Asset/AssetPreviewModal';
import { BRANCH_TEMPLATE_FORMATS } from './BranchTemplates';
import { ListCell } from 'components/Table/Cell/ListCell';
import { useMemo } from 'react';

interface TableProps {
  data?: MediaSequenceResponse;
  isFetching: boolean;
  disabled?: boolean;
}

export const MediaSequenceBranchResultTable = ({
  data,
  isFetching,
}: TableProps) => {
  const isMobile = useIsMobile();

  const columns = useMemo(() => {
    const baseColumns: ColumnDef<MediaSequenceBranchResponse>[] = [
      {
        accessorKey: 'name',
        header: 'Format',
        enableSorting: false,
        cell: (e) => {
          const format = BRANCH_TEMPLATE_FORMATS.find(
            (f) =>
              f.height === e.row.original.height &&
              f.width === e.row.original.width
          );
          return format
            ? format.name
            : `${e.row.original.width}x${e.row.original.height}`;
        },
      },
      {
        accessorKey: '_recommendedUses',
        header: 'Anbefalet brug',
        enableSorting: false,
        cell: (e) => {
          const format = BRANCH_TEMPLATE_FORMATS.find(
            (f) =>
              f.height === e.row.original.height &&
              f.width === e.row.original.width
          );
          return format ? (
            <ListCell
              items={format.recommendedUses}
              textSelector={(e) => e}
              emptyListText="Ikke defineret"
              maxItemsToShow={10}
            />
          ) : null;
        },
      },
      {
        accessorKey: '_result',
        header: 'Resultat',
        enableSorting: false,
        cell: (e) =>
          e.row.original.asset != null ? (
            <div id="results-result" className="max-w-60">
              <AssetPreviewModal asset={e.row.original.asset} />
            </div>
          ) : (
            <span className="loading loading-spinner text-primary loading-md"></span>
          ),
      },
      {
        accessorKey: '_',
        header: 'Handlinger',
        enableSorting: false,
        cell: (e) => (
          <div className="flex space-x-3">
            {e.row.original.asset ? (
              <LinkCell
                icon={
                  <AnimatedIcon
                    icon="download-icon"
                    className="h-8 md:h-6 w-8 md:w-6"
                  />
                }
                path={e.row.original.asset.url}
                tooltip="Download resultat"
                isExternal
              />
            ) : null}
          </div>
        ),
      },
    ];
    return baseColumns;
  }, []);

  const table = useGetCustomReactTable(
    data?.branches ?? [],
    -1,
    columns,
    undefined,
    {
      columnVisibility: {
        width: !isMobile,
        height: !isMobile,
      },
    }
  );

  return (
    <>
      <CustomTable table={table} isLoading={isFetching} hidePagination />
    </>
  );
};
