import {
  MediaSequenceAssetMutationCreateRequest,
  MediaSequenceAssetMutationUpdateRequest,
  MediaSequenceAssetResponse,
} from 'api/core';
import { VolumePicker } from 'components/Form/VolumePicker';
import { VideoPreviewWithTrim } from 'components/Video/VideoPreviewWithTrim';
import { useFormContext } from 'react-hook-form';

const VOLUME_DEFAULT = 1;
const VOLUME_STEP = 0.1;
const VOLUME_MIN = 0;
const VOLUME_MAX = 1;
const VOLUME_SHOW_ZERO_STEP = false;

interface AudioAdjustmentsProps {
  asset: MediaSequenceAssetResponse;
}

export const AudioAdjustments = ({ asset }: AudioAdjustmentsProps) => {
  const { register, watch, setValue } = useFormContext<
    | MediaSequenceAssetMutationCreateRequest
    | MediaSequenceAssetMutationUpdateRequest
  >();

  const assetTrimMutation = asset.mutations.find(
    (e) => e.trimVideo !== undefined
  )?.trimVideo;

  return (
    <>
      <VolumePicker
        registerFn={() =>
          register('audioAdjustments.volume', {
            required: true,
          })
        }
        watchFn={() => watch('audioAdjustments.volume') || 0}
        setFn={(value: number) => setValue('audioAdjustments.volume', value)}
        min={VOLUME_MIN}
        max={VOLUME_MAX}
        step={VOLUME_STEP}
        defaultVolume={VOLUME_DEFAULT}
        showZeroStep={VOLUME_SHOW_ZERO_STEP}
        showCurrentValueInTop={false}
        maxVolumeLabel="Original"
      />

      <VideoPreviewWithTrim
        asset={asset.asset}
        className="w-full max-h-[60vh]"
        volume={watch('audioAdjustments.volume') || undefined}
        loop
        trimStart={assetTrimMutation?.trimStartSeconds}
        trimEnd={assetTrimMutation?.trimEndSeconds}
      />

      <div className="form-control items-start hidden">
        <label className="cursor-pointer label">
          <span className="label-text me-2">Normaliser lydniveau</span>
          <input
            {...register('audioAdjustments.normalizeAudio')}
            type="checkbox"
            className="toggle toggle-primary"
          />
        </label>
      </div>

      <div className="form-control items-start hidden">
        <label className="cursor-pointer label">
          <span className="label-text me-2">Fjern støj</span>
          <input
            {...register('audioAdjustments.removeNoise')}
            type="checkbox"
            className="toggle toggle-primary"
          />
        </label>
      </div>
    </>
  );
};
