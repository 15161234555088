import { AssetResponse, AssetSimpleResponse, EAssetType } from 'api/core';
import { VideoPreviewWithTrim } from './VideoPreviewWithTrim';
import { useEffect, useRef } from 'react';

interface VideoPreviewProps {
  asset: AssetResponse | AssetSimpleResponse;
  className?: string | undefined;
  trimStart?: number;
  trimEnd?: number;
  volume?: number;
}

export const VideoPreview = ({
  asset,
  className,
  trimStart,
  trimEnd,
  volume = 1,
}: VideoPreviewProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    video.volume = volume;
  }, [volume, videoRef]);

  if (asset.type !== EAssetType.Video) return null;

  if (trimStart != null && trimEnd != null) {
    return (
      <VideoPreviewWithTrim
        asset={asset}
        trimStart={trimStart}
        trimEnd={trimEnd}
        style={{ width: '100%' }}
        className={className}
        volume={volume}
      />
    );
  }

  return (
    <video
      className={className}
      controls
      crossOrigin="anonymous"
      ref={videoRef}
    >
      <source src={asset.url} type="video/mp4" /> Your browser does not support
      the video tag.
    </video>
  );
};
