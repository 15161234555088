import {
  EAssetMutationType,
  EAssetType,
  MediaSequenceAssetMutationCreateRequest,
  MediaSequenceAssetMutationResponse,
  MediaSequenceAssetMutationUpdateRequest,
  MediaSequenceAssetResponse,
} from 'api/core';
import {
  useCreateMediaSequenceAssetMutation,
  useUpdateMediaSequenceAssetMutation,
} from 'api/useMediaSequenceAssetsApi';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { getAvailableAssetMutations } from 'utils/available-mutations';
import { assetMutationTranslate } from 'utils/enum-translate';
import { ColorAdjustments } from './Types/ColorAdjustments';
import { ImageOverlay } from './Types/ImageOverlay';
import { ImageToVideo } from './Types/ImageToVideo';
import { AddDynamicOverlay } from './../MediaSequenceBranchMutation/Types/AddDynamicOverlay';
import { twMerge } from 'tailwind-merge';
import { TrimVideo } from './Types/TrimVideo';
import { usePrevious } from '@dnd-kit/utilities';
import { Voiceover } from './Types/Voiceover';
import { AudioAdjustments } from './Types/AudioAdjustments';

interface MediaSequenceAssetMutationFormProps {
  asset: MediaSequenceAssetResponse;
  targetAssetMutation?: MediaSequenceAssetMutationResponse;
  projectId?: string;
  setTitle: Dispatch<SetStateAction<string>>;
  onSuccess: () => void;
  onCancel: () => void;
  inDialog?: boolean;
  disabled?: boolean;
  canChangeMutationType: boolean;
  cancelButtonText?: string;
}

export const MediaSequenceAssetMutationForm = ({
  asset,
  targetAssetMutation,
  projectId,
  setTitle,
  onSuccess,
  onCancel,
  inDialog,
  disabled,
  canChangeMutationType,
  cancelButtonText = 'Annuller',
}: MediaSequenceAssetMutationFormProps) => {
  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreateMediaSequenceAssetMutation();
  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateMediaSequenceAssetMutation();

  const [assetDuration, setAssetDuration] = useState<number>(0);
  const [saveButtonText, setSaveButtonText] = useState<string>();
  const [preOnSubmitFn, setPreOnSubmitFn] = useState<
    (
      req:
        | MediaSequenceAssetMutationCreateRequest
        | MediaSequenceAssetMutationUpdateRequest
    ) => Promise<boolean>
  >(async () => true);
  const [isRunningPreOnSubmitFn, setIsRunningPreOnSubmitFn] = useState(false);
  const [
    fieldIdsOfCurrentDynamicTemplate,
    setFieldIdsOfCurrentDynamicTemplate,
  ] = useState<string[]>([]);

  const methods = useForm<
    | MediaSequenceAssetMutationCreateRequest
    | MediaSequenceAssetMutationUpdateRequest
  >({
    defaultValues: targetAssetMutation,
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    control,
    formState: { isValid, errors },
  } = methods;

  // We have to disable the form like this, if we set it in the useForm constructor it will not load in default values
  useEffect(() => {
    control._disableForm(disabled);
  }, [control, disabled]);

  useEffect(() => {
    if (!asset.asset) return;

    if (asset.asset.type === EAssetType.Video) {
      const video = document.createElement('video');
      video.src = asset.asset.url;
      video.onloadedmetadata = () => {
        setAssetDuration(Math.ceil(video.duration * 10) / 10);
      };
    } else if (asset.asset.type === EAssetType.Image) {
      const imageToVideoMutation = asset.mutations.find(
        (e) => e.type === EAssetMutationType.ImageToVideo
      );
      setAssetDuration(
        imageToVideoMutation?.imageToVideo?.durationInSeconds ?? 5
      );
    } else {
      setAssetDuration(5);
    }
  }, [asset.asset, targetAssetMutation, asset.mutations]);

  useEffect(() => {
    if (
      !targetAssetMutation ||
      targetAssetMutation.type !== EAssetMutationType.ImageOverlay
    ) {
      setValue('overlay.durationInSeconds', Math.min(30, assetDuration));
    }
  }, [assetDuration, setValue, targetAssetMutation]);

  const availableMutations = getAvailableAssetMutations(
    asset,
    targetAssetMutation
  );

  const typeSelected = watch('type') ?? availableMutations[0];
  const previousTypeSelected = usePrevious(typeSelected);

  useEffect(() => {
    if (typeSelected === previousTypeSelected) return;
    if (!previousTypeSelected) return;

    // TODO: There must be a better way to do this,
    // Maybe each component should clean up like a ngOnDestroy, where
    // they reset their own errors
    if (previousTypeSelected === EAssetMutationType.TrimVideo) {
      // Clear errors that are related to trim video
      clearErrors('trimVideo.trimStartSeconds');
    }
  }, [typeSelected, previousTypeSelected, clearErrors]);

  useEffect(() => {
    if (typeSelected === EAssetMutationType.TrimVideo) {
      setSaveButtonText('Klip');
      setTitle('Klip video');
    } else if (targetAssetMutation) {
      setTitle('Opdater effekt');
      setSaveButtonText('Opdater gemt effekt');
    } else {
      setTitle('Tilføj effekt');
      setSaveButtonText('Gem effekt');
    }
  }, [targetAssetMutation, setTitle, typeSelected]);

  const onSubmit = handleSubmit(async (result) => {
    if (preOnSubmitFn && typeof preOnSubmitFn === 'function') {
      setIsRunningPreOnSubmitFn(true);
      try {
        const shouldSubmit = await preOnSubmitFn(result);
        setIsRunningPreOnSubmitFn(false);
        if (!shouldSubmit) {
          console.warn('Pre onSubmit function returned false');
          return;
        }
      } catch (error) {
        setIsRunningPreOnSubmitFn(false);
        console.error('Error in pre onSubmit function', error);
        return;
      }
    }
    const resetters: Record<
      | keyof MediaSequenceAssetMutationCreateRequest
      | keyof MediaSequenceAssetMutationUpdateRequest,
      (result: MediaSequenceAssetMutationCreateRequest) => void
    > = {
      addDynamicOverlay: (result) => (result.addDynamicOverlay = undefined),
      colorAdjustments: (result) => (result.colorAdjustments = undefined),
      imageToVideo: (result) => (result.imageToVideo = undefined),
      overlay: (result) => (result.overlay = undefined),
      voiceover: (result) => (result.voiceover = undefined),
      trimVideo: (result) => (result.trimVideo = undefined),
      audioAdjustments: (result) => (result.audioAdjustments = undefined),
      mutationOptionsJson: () => undefined,
      type: () => undefined,
    };

    const resetPropertiesExcept = (
      key:
        | keyof MediaSequenceAssetMutationCreateRequest
        | keyof MediaSequenceAssetMutationUpdateRequest
    ) => {
      const keys = Object.keys(resetters) as (
        | keyof MediaSequenceAssetMutationCreateRequest
        | keyof MediaSequenceAssetMutationUpdateRequest
      )[];
      for (const k of keys) {
        if (k !== key) {
          resetters[k](result);
        }
      }
    };

    if (typeSelected === EAssetMutationType.ColorAdjustments) {
      resetPropertiesExcept('colorAdjustments');
    } else if (typeSelected === EAssetMutationType.AudioAdjustments) {
      resetPropertiesExcept('audioAdjustments');
    } else if (typeSelected === EAssetMutationType.ImageToVideo) {
      resetPropertiesExcept('imageToVideo');
    } else if (typeSelected === EAssetMutationType.ImageOverlay) {
      resetPropertiesExcept('overlay');
    } else if (typeSelected === EAssetMutationType.Voiceover) {
      resetPropertiesExcept('voiceover');
      if (result.voiceover) {
        result.voiceover.delayInSeconds ||= 0;
      }
    } else if (typeSelected === EAssetMutationType.AddDynamicOverlay) {
      resetPropertiesExcept('addDynamicOverlay');
      if (result.addDynamicOverlay) {
        // only keep the keys where the key ends with a id from "fieldIdsOfCurrentDynamicTemplate"
        // this is to remove all the other fields that are not in the current dynamic template
        result.addDynamicOverlay.dynamicTemplateFieldValues = !result
          .addDynamicOverlay.dynamicTemplateFieldValues
          ? {}
          : Object.fromEntries(
              Object.entries(
                result.addDynamicOverlay.dynamicTemplateFieldValues
              ).filter(([key]) =>
                fieldIdsOfCurrentDynamicTemplate.includes(key.slice(-36))
              )
            );
      }
    } else if (typeSelected === EAssetMutationType.TrimVideo) {
      resetPropertiesExcept('trimVideo');

      if (!result.trimVideo) {
        result.trimVideo = {
          trimStartSeconds: 0,
          trimEndSeconds: assetDuration,
        };
      }

      if (result.trimVideo) {
        result.trimVideo.trimStartSeconds ??= 0;
      }

      // If end is less than start, then swap them
      if (
        result.trimVideo &&
        result.trimVideo.trimEndSeconds <= result.trimVideo.trimStartSeconds
      ) {
        const temp = result.trimVideo.trimEndSeconds;
        result.trimVideo.trimEndSeconds = result.trimVideo.trimStartSeconds;
        result.trimVideo.trimStartSeconds = temp;
      }
    }
    if (targetAssetMutation) {
      await updateAsync({
        id: targetAssetMutation.id,
        mediaSequenceAssetMutationUpdateRequest: result,
      });
    } else {
      if (!asset.id) return;
      await createAsync({
        assetId: asset.id,
        mediaSequenceAssetMutationCreateRequest: result,
      });
    }
    onSuccess();
  });

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={onSubmit}
        className={twMerge(
          'space-y-2',
          typeSelected !== EAssetMutationType.AddDynamicOverlay &&
            'w-[80vw] md:w-[512px]',
          typeSelected === EAssetMutationType.AddDynamicOverlay && 'w-full'
        )}
      >
        <div
          className={twMerge(
            'form-control',
            !canChangeMutationType && 'hidden'
          )}
        >
          <label className="label">
            <span className="label-text">Type</span>
          </label>
          <select
            data-testid="media-sequence-asset-mutation-type"
            {...register('type', { required: true })}
            className="select select-bordered"
          >
            {availableMutations.map((type) => (
              <option
                data-testid={`media-sequence-asset-mutation-type-${type}`}
                value={type}
                key={type}
              >
                {assetMutationTranslate(type)}
              </option>
            ))}
          </select>
        </div>

        {typeSelected === EAssetMutationType.AddDynamicOverlay ? (
          <AddDynamicOverlay
            mediaSequenceId={asset.mediaSequenceId}
            dynamicTemplateId={
              targetAssetMutation?.addDynamicOverlay?.dynamicTemplateId
            }
            asset={asset}
            inDialog={inDialog}
            // We have to pass fallback values if the form is disabled, because the form will not load the default values
            fallBackdynamicTemplateFieldValues={
              disabled
                ? targetAssetMutation?.addDynamicOverlay
                    ?.dynamicTemplateFieldValues
                : undefined
            }
            setFieldIdsOfCurrentDynamicTemplate={
              setFieldIdsOfCurrentDynamicTemplate
            }
          />
        ) : null}

        {/* // TODO: Implement audio adjustments */}
        {typeSelected === EAssetMutationType.AudioAdjustments ? (
          <AudioAdjustments asset={asset} />
        ) : null}

        {typeSelected === EAssetMutationType.ColorAdjustments ? (
          <ColorAdjustments asset={asset} />
        ) : null}

        {typeSelected === EAssetMutationType.TrimVideo ? (
          <TrimVideo asset={asset.asset} />
        ) : null}

        {typeSelected === EAssetMutationType.ImageOverlay ? (
          <ImageOverlay
            targetAssetMutation={targetAssetMutation}
            asset={asset}
            projectId={projectId}
            inDialog={inDialog}
          />
        ) : null}

        {typeSelected === EAssetMutationType.Voiceover ? (
          <Voiceover
            targetAssetMutation={targetAssetMutation}
            asset={asset.asset}
            imageToVideoMutation={asset.mutations.find(
              (e) => e.type === EAssetMutationType.ImageToVideo
            )}
            trimVideoMutation={asset.mutations.find(
              (e) => e.type === EAssetMutationType.TrimVideo
            )}
            projectId={projectId}
            inDialog={inDialog}
            setPreOnSubmitFn={setPreOnSubmitFn}
          />
        ) : null}

        {typeSelected === EAssetMutationType.ImageToVideo ? (
          <ImageToVideo asset={asset.asset} />
        ) : null}

        <div className="flex justify-center space-x-4 pt-4">
          <button
            data-testid="media-sequence-asset-mutation-submit"
            className="btn btn-primary"
            disabled={
              isPendingCreate ||
              isPendingUpdate ||
              disabled ||
              !isValid ||
              Object.keys(errors).length > 0 ||
              isRunningPreOnSubmitFn
            }
          >
            {saveButtonText}
          </button>
          {onCancel ? (
            <button
              data-testid="media-sequence-asset-mutation-cancel"
              type="button"
              className="btn"
              onClick={onCancel}
            >
              {cancelButtonText}
            </button>
          ) : null}
        </div>
      </form>
    </FormProvider>
  );
};
